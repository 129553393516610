.product_list_container {
  padding: 10px 0px;
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  // align-items: center;
  .item {
    position: relative;
    flex: 0 0 240px;
    // min-width: 260px;
    // max-width: 25%;
    border: 1px solid silver;
    // border-radius: 5px;
    margin-right: 15px;
    margin-top: 15px;
    .imgbox {
      padding: 0px;
    }
    img {
      width: 100%;
      height: 240px;
      // border-radius: 5px;
    }
    .titlebox {
      padding: 5px;
      font-size: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      display:-webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .pricebox {
      padding: 10px 5px;
      text-align: center;
      span.price {
        color: #9B3C44 !important;
        font-size: 16px !important;
        font-family: Lato,sans-serif !important;
        font-style: normal !important;
        font-weight: 400 !important;
      }
      span.comparePrice {
        color: #999999 !important;
        font-size: 16px !important;
        font-family: Lato,sans-serif !important;
        font-style: normal !important;
        font-weight: 400 !important;
        text-decoration: line-through;
        margin-left: 10px;
      }
    }
    .delete {
      font-size: 20px;
      position: absolute;
      right: 1px;
      bottom: 1px;
      padding: 10px 10px;
      color: grey;
      text-align: center;
      cursor: pointer;
      &:hover {
        color: red
      }
    }
    .offview {
      position: absolute;
      width: 45px;
      height: 45px;
      background-color: rgb(235, 91, 91);
      color: white;
      border-radius: 45px;
      text-align: center;
      font-weight: 600;
      font-size: 12px;
      right: 4px;
      top: 4px;
      opacity: 0.9;
      
      div {
        display: block;
        font-weight: 700;
        text-align: center;

      }
      div:first-child {
        padding: 8px 0px 0px 0px;
        font-size: 12px;
      }
      div:last-child {
        padding: 0px;
        margin-top: -5px;
        font-size: 11px;
      }

      // font-family: Arial, Helvetica, sans-serif;
      // word-wrap: break-word;
    }
    .addToCart {
      text-align: center;
      button {
        font-size: 14px;
        position: relative;
        display: inline-block;
        padding: 0.5em 1.2em;
        text-decoration: none;
        background: #fd9535;
        border-radius: 4px;
        font-weight: 500;
        cursor: pointer;
      }
      padding: 10px;
    }
  }
}