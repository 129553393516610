.colorPanel {
  .row {
    font-weight: 400;
    padding: 5px 0px;
  }
  p {
    padding: 5px 0px;
  }

}
.customAttrs {
  font-weight: 700;
  color: grey;
}

.ps {
  padding: 10px;
  color: grey;
  font-weight: 500;
}

.png {
  color: gray;
}