.selectedBox {
  padding: 5px 0px;
}
.tableList {
  width: 100%;
  border-collapse: collapse;
  tr {
    td {
      padding: 6px 4px !important;
      border-bottom: 1px dashed #dfe3e8;
    }
    td img {
      width: 35px;
      height: 35px;
      border-radius: 5px;
    }
    td label {
      padding: 2px 5px 2px 0px;
    }
  }

}