.listAction {
  h1 {
    font-size: 17px;
  }
  .actionInfo {
    width: 100%;
    height: 100%;
    tr {
      td {
        padding: 8px 0px;
      }
      // td:first-child {
      //   color: grey;
      // }
      td:last-child {
        color: #48528b;
        font-weight: 500;
      }
    }
    .unactive {
      padding: 5px;
      background-color: grey;
      color: white;
      border-radius: 5px;
      font-weight: 800;
      font-size: 11px;
    }
    .active {
      padding: 7px;
      background-color: #09b786;
      color: white;
      border-radius: 8px;
      font-weight: 800;
      font-size: 11px;
    }

  }
  .create {
    padding: 5px 0px;
    text-align: right;
    color: grey;
    font-size: 13px;
    label {
      padding: 3px;
      border-radius: 5px;
      background-color: #b3f5ed;
      font-size: 12px;
    }
  }
  .discountTitle {
    font-weight: 400;
  }
  .discountVal {
    font-weight: 700;
    font-size: 15px;
  }
  .splitTitle {
    font-weight: 700;
  }
  .splitTraffic {
    font-weight: 500;
    font-size: 14px;
  }
}

.emtpyActiveAction {
  padding: 50px 20px;
  background-color: #fdf7c7;
  border: dashed 3px #f7d850;
  margin-bottom: 20px;
  text-align: center;
  font-size: 20px;
  color: grey;
  font-weight: 700;
}

.tblist {
  margin-top: 15px;
}

.stTable, .upsellTable {
  width: 70%;
  td {
    padding: 5px 3px;
    text-align: left;
    font-weight: 500;
    label {
      color: #636060;
      font-weight: 300;
    }
  }
  td.stTd {
    font-weight: 500;
  }
}
.btngroup {
  text-align: center;
  padding: 10px 0px;
}
.arrow {
  font-weight: 800;
  font-size: 18px;
}

@media screen and  (min-width: 40em) {
  .actionHeading {
    font-size: 1.6rem;
  }
}

.actionHeading {
    font-size: 1.7rem;
    font-weight: 600;
    line-height: 2.4rem;
    margin: 0;
    span {
      padding: 5px 9px;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background-color: #f7ca06;
      margin-right: 6px;
      display: inline-block;
      line-height: 100%;
      text-align: center;
      font-size: 1.5rem;
    }
}

.actionFlowShow {
  label {
    padding: 5px;
    border: solid 1px #e6e2e2;
    background-color: #e1e3f5;
    border-radius: 7px;
    text-transform: capitalize;
  }
}
@media only screen and (max-width: 600px) {
  .actionFlowShow {
    label {
      padding: 5px;
      border: none;
      background-color: white;
      border-radius: 0px;
      text-transform: capitalize;
    }
  }
}

.unactiveListActionFlow {
  text-transform: capitalize;
}
.pieContainer {
  padding: 10px 0px;
}
.innerTable {
  width: 100%;
  border-collapse: collapse;
  td {
    padding: 6px;
    border-bottom: solid 1px #dad6d6;
  }
}

.upgrade {
  padding: 15px;
  background-color: #CBFBF0;
  border: solid 1px #99C5CC;
  border-radius: 6px;
  h5 {
    font-weight: 500;
  }
  ul {
    margin: 0px;
    // padding: 0px;
    li {
      padding: 5px;
    }
  }
  .opt {
    text-align: right;
    .button {
      cursor: pointer;
      text-decoration: underline;
      color: blue;
      font-weight: 800;
      &:hover {
        color: red;
      }
    }
  }
}


.planList {
  border: solid 1px silver;
  border-radius: 8px;
  padding: 15px;
  margin: 20px auto;
  width: 550px;
  box-shadow: 1px 1px 5px silver;
  h4 {
    font-size: 16px;
    color: grey;
    padding: 2px 0px;
  }
  h3 {
    font-size: 22px;
    color: black;
    padding: 15px 0px;
    font-weight: 500;
  }
  ul {
    margin: 0px;
    list-style: none;
    padding: 0px;
    li {
      font-size: 16px;
      padding: 8px;
      &::before {
        content: '\2713  ';
        font-size: 18px;
        color: green;
        font-weight: 600;
      }
    }

  }
  .opt {
    padding: 20px 15px 15px 15px ;
    text-align: center;
    border-top: solid 1px silver;
   
  }
}

.upsellTable {
  width: 100%;
}
.upsellSt {
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    li {
      padding: 10px;
      img {
        width: 35px;
        height: 35px;
      }
      span {
        padding: 5px;
        &:last-child{
          color: grey;
        }
        label {
          color: red;
          font-weight: 500;
        }
      }

    }
  }
}

