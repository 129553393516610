$titleFontFamily: 'Gideon Roman', verdana, arial;
$subtitleFontFamily: 'Andada Pro', verdana, arial;
$discountFontFamily: 'Nanum Myeongjo', verdana, arial;
// $discountFontFamily: 'Kaisei HarunoUmi', verdana, arial;
$subscribeFontFamily: 'Limelight', verdana, arial;
$gotButtonFontFamily: 'Limelight', verdana, arial;

.container {
  position: relative;
  margin: 0px auto;
  padding: 10px 15px 10px 15px;
  // width: 580px;
  height: 100%;
  border-radius: 8px;
  background-color: #ef5208;
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 3px 9px;
  display: grid;
  grid-template-columns: none;
  grid-gap: 0.8rem;
  width: fit-content;
  block-size: fit-content;
}
@media (min-width: 600px) {
  .container { grid-template-columns: 50% 50%; }
}
.cardItem {
  width: 290px;
}

.title {
  text-align: center;
  font-family: $titleFontFamily;
  font-size: 26px;
  padding: 15px 0px;
  font-weight: 500;

}
.sub_title {
  text-align: center;
  font-size: 20px;
  font-family: $subtitleFontFamily;
  font-weight: 500;
  padding: 5px 0px;
  white-space: pre-line;
  text-shadow: none;
  line-height: 2.8rem;

}

.discount {
  margin: 0px auto;
  padding: 25px 0px;
  text-align: center;
  font-family: $discountFontFamily;
  font-size: 42px;
  font-weight: 800;
  // font-style: italic;
}
.subscribe {
  text-align: center;
  padding: 10px 13px;
  input {
    font-family: $subscribeFontFamily;
  }
}

.operationArea {
  padding: 10px;
  text-align: center;
  width: 100%;
  button {
    font-family: $gotButtonFontFamily;
    font-size: 20px;
    position: relative;
    display: inline-block;
    padding: 0.45em 1.9em;
    text-decoration: none;
    background: #fd9535;
    border-radius: 4px;
    font-weight: bold;
    border: solid 2px transparent;
    &:hover {
      box-shadow: 0 0 2px #fff;
    }
  }
  
}
.prompt {
  padding: 10px 0px 5px 0px;
  text-align: center;
  font-size: 8pt;
  width: 100%;
}

.close {
  position: absolute;
  top: 5px;
  right: 15px;
  color: rgb(58, 55, 55);
  cursor: pointer;
  font-size: 18px;
}