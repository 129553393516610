$titleFontFamily:'Limelight', verdana, arial;
$subtitleFontFamily:'Work Sans', verdana, arial;
$submitFontFamily:$subtitleFontFamily;


.subscribebox {
  position: relative;
  // width: 310px;
  margin: 0px auto;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 3px 9px;
  border-radius: 8px;
  padding: 10px;
  // min-height: 500px;
  display: grid;
  grid-template-columns: none;
  grid-gap: 0.8rem;
  width: fit-content;
  block-size: fit-content;

  .title {
    text-align: center;
    font-family: $titleFontFamily;
    padding: 20px 5px 10px 5px;
    font-size: 20px;
    font-weight: 600;
  }
  .subTitle {
    text-align: center;
    font-family: $subtitleFontFamily;
    padding: 10px 5px 10px 5px;
    font-size: 16px;
    font-weight: 600;
  }
  .subscribe {
    padding: 10px;
    font-family: $subtitleFontFamily;
  }
  .unsubscribe {
    padding: 10px;
    text-align: center;
    font-weight: 400;
    font-family: $subtitleFontFamily;
    font-size: 12px;
  }
  .submit {
    padding: 10px 10px;
    text-align: center;
    button {
      font-family: $submitFontFamily;
      font-size: 15px;
      position: relative;
      display: inline-block;
      padding: 0.5em 1.8em;
      text-decoration: none;
      background: #fd9535;
      border-radius: 4px;
      font-weight: bold;
      border: solid 2px transparent;
      &:hover {
        box-shadow: 0 0 2px #fff;
      }
    }
  }
  .close {
    position: absolute;
    top: 5px;
    right: 15px;
    color: rgb(58, 55, 55);
    cursor: pointer;
    font-size: 18px;
  }
}

@media (min-width: 600px) {
  .subscribebox { grid-template-columns: 50% 50%; }
}
.cardItem {
  width: 290px;
}