$titleFontFamily:'Limelight', verdana, arial;
$subtitleFontFamily:'Manrope', verdana, arial;
$optionFontFamily:'Manrope', verdana, arial;
$submitFontFamily: 'Limelight', verdana, arial;


.surveybox {
  position: relative;
  // width: 310px;
  margin: 0px auto;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 3px 9px;
  border-radius: 8px;
  padding: 10px;

  display: grid;
  grid-template-columns: none;
  grid-gap: 0.8rem;
  width: fit-content;
  block-size: fit-content;

  .title {
    text-align: center;
    font-family: $subtitleFontFamily;
    padding: 10px;
    font-size: 25px;
    font-weight: 700;
  }
  .subTitle {
    text-align: left;
    font-family: $subtitleFontFamily;
    padding: 5px 5px 10px 8px;
    font-size: 16px;
    font-weight: 600;
  }
  .options {
    text-align: left;
    ul {
      font-family: $optionFontFamily;
      list-style: none;
      padding: 0px;
      margin: 0px;
      width: 100%;
      li {
        font-size: 14px;
        padding: 7px 5px;
        font-weight: 600;
        textarea {
          width: 100%;
          border-radius: 3px;
          font-family: $submitFontFamily;
          font-weight: 500;
        }
      }
    }
    .textareaBox {
      padding: 5px 4px;

    }
  }
  .submit {
    padding: 5px 10px;
    text-align: center;
    button {
      font-family: $submitFontFamily;
      font-size: 15px;
      position: relative;
      display: inline-block;
      padding: 0.45em 1.8em;
      text-decoration: none;
      background: #fd9535;
      border-radius: 4px;
      font-weight: bold;
      border: solid 2px transparent;
      &:hover {
        box-shadow: 0 0 2px #fff;
      }
    }
  }
  .close {
    position: absolute;
    top: 5px;
    right: 15px;
    color: white;
    cursor: pointer;
    font-size: 18px;
  }
}

@media (min-width: 600px) {
  .surveybox { grid-template-columns: 50% 50%; }
}
.cardItem {
  width: 290px;
}