.colorPanel {
  .row {
    font-weight: 400;
    padding: 5px 0px;
  }
  p {
    padding: 5px 0px;
  }

}
.customAttrs {
  font-weight: 700;
  color: grey;
}

.fileOnDeviceTitle {
  padding: 5px 0px;
}

.urlPattern {
  padding: 7px 0px;
}
