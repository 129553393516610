.cannotFound {
  padding: 10px 22px;
  font-weight: 400;
  color: grey;
  label {
    color: darkblue;
  }
}

.operateView {
  // button:last-child {
  //   float: right;
  // }
  // clear: both;
  padding: 20px 0px;
}

.colorPanel {
  .row {
    font-weight: 400;
    padding: 5px 0px;
  }
  p {
    padding: 5px 0px;
  }

}
.customAttrs {
  font-weight: 700;
  color: grey;
}