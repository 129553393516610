

.Polaris-Page-Header {
  padding-top: 2px;
}

.Polaris-Page {
  max-width: 100% !important;
  /* padding: 0px 10px !important; */
}

.Polaris-ChoiceList {
  padding: 1px;
}

/* @media (min-width: 46.5em) {
  .Polaris-Page {
    padding: 0px !important;
  }
} */
.Polaris-DisplayText--sizeLarge {
  font-size: 2rem !important;
}
@media (min-width: 30.625em) {
  .Polaris-Card {
    border-radius: 0px !important;
    border-radius: var(--p-border-radius-wide,0px);
  }
}
.Polaris-Modal-Header {
  padding: 0.5rem 2rem !important;
}

.languageSelector {
  position: absolute;
  top: 15px;
  right: 20px;
  
}
.languageSelector .Polaris-Button--plain {
  margin-left: 10px !important;
}
.languageSelector span.language {
  cursor: pointer;
  color: #2763C4;
  /* font-weight: 400; */
  margin-left: 10px;
}
.languageSelector span.tools {
  cursor: pointer;
  color: #2763C4;
  font-weight: 400;
  margin-left: 15px;
  text-decoration: underline;
}

.languageSelector span.active {
  text-decoration: underline;
  color: red;
  /* font-weight: 600; */
}
.languageSelector span:hover {
  color: red;
}
.comments {
  margin: 0px;
  padding: 0px;
  color: grey;
  text-align: center;
}