.productList {
  width: 100%;
  padding: 0px 0px;
  border-top: 1px solid #dfe3e8;
  table {
    width: 100%;
    border-collapse: collapse;
    tr {
      padding: 5px 10px;
    }
    tr:hover {
      background-color: #eee;
      cursor: pointer;
    }
    td {
      padding: 10px 3px;
      border-bottom: 1px solid #dfe3e8;
    }
    td img {
      width: 40px;
      height: 40px;
      border-radius: 5px;
    }
    td.rowHeader {
      padding: 10px 3px 10px 15px;
    }
  }
}