.selectedBox {
  padding: 5px 0px;
}
.tableList {
  width: 100%;
  border-collapse: collapse;
  tr {
    padding: 5px 10px;
  }
  tr:hover {
    background-color: #eee;
    cursor: pointer;
  }
  td {
    padding: 6px 3px;
    border-bottom: 1px solid #dfe3e8;
  }
  td img {
    width: 35px;
    height: 35px;
    border-radius: 5px;
  }
  td.rowHeader {
    padding: 10px 3px 10px 15px;
  }
}