.colorPanel {
  .row {
    font-weight: 400;
    padding: 5px 0px;
  }
  p {
    padding: 5px 0px;
  }

}
.customAttrs {
  font-weight: 700;
  color: grey;
}
.optionsTable {
  width: 100%;
  border-collapse: collapse;
  td {
    padding: 5px;
    border-bottom: 1px dashed silver;
  }
}
.png {
  color: gray;
}